<template>
    <mt-tabbar v-model="selected">
        <mt-tab-item id="1">
            <router-link to="/home" tag="div">
                <p class="iconfont icon-shouye"></p>首页
            </router-link>
        </mt-tab-item>
        <mt-tab-item id="2">
            <router-link to="/ShopIndex" tag="div">
                <p class="iconfont icon-shangcheng"></p>精选好物
            </router-link>
        </mt-tab-item>
        <mt-tab-item id="4">
            <router-link to="/my" tag="div">
                <p class="iconfont icon-user"></p>我的
            </router-link>
        </mt-tab-item>
    </mt-tabbar>
</template>
<script>
    export default {
        props: {
            selectedId: {
                type: String,
                default () {
                    return "";
                }
            }
        },
        data() {
            return {
                num: 0,
                selected: this.selectedId
            };
        },
        methods: {},
        mounted() {

        }
    };
</script>
<style lang="scss" scoped>
    .mint-tabbar {
        position: fixed;
        bottom: 0;
        left: 0;
        color: #333333;
        height: 1.15rem;
        background-color: #ffffff;
        box-shadow: 0.02rem 0 0.02rem #eaeaea;

        .mint-tab-item.is-selected {
            background: none;
            color: #e80b1b;
        }

        .iconfont {
            font-size: 0.46rem !important;
            margin-bottom: 0.15rem !important;
        }
    }
</style>
