<template>
	<div>
		<!-- 客服 -->
		<div class="fixed">
			<ul class="fixed-list">
				<li @click="showpopup">
					<img src="@/assets/kefu.png" alt />
					<p>联系客服</p>
				</li>
				<li @click="backTop" v-if="btnFlag">
					<img src="@/assets/top.png" alt />
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				btnFlag: false,
			}
		},
		destroyed() {
			window.removeEventListener("scroll", this.scrollToTop);
		},
		methods: {
			// 回到顶部
			backTop() {
				const that = this;
				let timer = setInterval(() => {
					let ispeed = Math.floor(-that.scrollTop / 5);
					document.documentElement.scrollTop = document.body.scrollTop =
						that.scrollTop + ispeed;
					if (that.scrollTop === 0) {
						clearInterval(timer);
					}
				}, 16);
			},
			// 滚动事件
			scrollToTop() {
				const that = this;
				let scrollTop =
					window.pageYOffset ||
					document.documentElement.scrollTop ||
					document.body.scrollTop;
				that.scrollTop = scrollTop;
				if (that.scrollTop > 60) {
					that.btnFlag = true;
				} else {
					that.btnFlag = false;
				}
			},
			//打开活动规则页面
			showpopup() {
				this.$store.dispatch('changeMakePhone', true);
			},
		},
		mounted() {
			window.addEventListener("scroll", this.scrollToTop);
		}
	}
</script>

<style lang="scss" scoped>
	.fixed {
		width: 1.15rem;
		position: fixed;
		right: 0.2rem;
		bottom: 2.4rem;
		z-index: 100;
	}

	.fixed-list li {
		width: 1.15rem;
		height: 1.15rem;
	}

	.fixed-list li p {
		font-size: 0.2rem;
		color: #ffffff;
	}

	.fixed-list li:nth-child(1) {
		background: rgba($color: #000000, $alpha: 0.62);
		border-radius: 50%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.fixed-list li:nth-child(1) img {
		width: 0.52rem;
		// height: 0.52rem;
		display: block;
	}

	.fixed-list li:nth-child(2) {
		width: 1.15rem;
		height: 1.15rem;
	}

	.fixed-list li:nth-child(2) img {
		width: 1.15rem;
		height: 1.15rem;
	}

	.icon-select>path {
		fill: red;
	}
</style>
