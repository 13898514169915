<template>
	<div class="home-wrap" ref="wrapper">
		<!-- 头部1 -->
		<div style="height:1rem">
			<home-header types="home" @changeCity="getSpotData"></home-header>
		</div>

		<!-- 数据轮播图 -->
		<mt-swipe :auto="3000" class="swiper" v-if="banners.length>0">
			<mt-swipe-item v-for="(item, index) in banners" :key="index">
				<!-- 跳转到卡券商品 -->
				<div v-if="item.click_types == 1">
					<router-link :to="'/details?goods_id=' + item.click_parame">
						<img :src="item.cover_imgurl" />
					</router-link>
				</div>
				<!-- 跳转到实物商品 -->
				<div v-else-if="item.click_types == 2">
					<router-link :to="'/ShopGoodsDetails?goods_id=' + item.click_parame">
						<img :src="item.cover_imgurl" />
					</router-link>
				</div>
				<!-- 跳转到积分商品 -->
				<div v-else-if="item.click_types == 3">
					<router-link :to="'/details?goods_id=' + item.click_parame">
						<img :src="item.cover_imgurl" />
					</router-link>
				</div>
				<!-- 跳转到外部链接 -->
				<div v-else-if="item.click_types == 4" @click="toRescue(item.click_parame)">
					<img :src="item.cover_imgurl" />
				</div>
				<img v-else :src="item.cover_imgurl" />
			</mt-swipe-item>
		</mt-swipe>

		<!-- 菜单列表 -->
		<mt-swipe class="swiper-wrap" :auto="0">
			<mt-swipe-item :auto="0" defaultIndex:0 v-for="(item, index) in columns" :key="index">
				<ul class="list-wrap">
					<div v-for="(child, childIndex) in item" :key="childIndex" class="asd">
						<!-- 系统固定链接 -->
						<div v-if="child.column_types == 0">
							<router-link :to="child.column_parame" tag="li">
								<img :src="child.cover_imgurl" alt />
								<p>{{ child.column_name }}</p>
							</router-link>
						</div>
						<!-- 外部链接 -->
						<div v-else-if="child.column_types == 1" @click="toRescue(child.column_parame)">
							<img :src="child.cover_imgurl" alt />
							<p>{{ child.column_name }}</p>
						</div>
						<!-- 卡券商品分类 -->
						<div v-else-if="child.column_types == 2">
							<router-link :to="'/classify?id=' + child.column_parame" tag="li">
								<img :src="child.cover_imgurl" alt />


								<p>{{ child.column_name }}</p>
							</router-link>
						</div>
						<!-- 实物商品分类 -->
						<div v-else-if="child.column_types == 3">
							<router-link :to="'/ShopMall?id=' + child.column_parame" tag="li">
								<img :src="child.cover_imgurl" alt />
								<p>{{ child.column_name }}</p>
							</router-link>
						</div>
					</div>
				</ul>
			</mt-swipe-item>
		</mt-swipe>

		<!-- 商品类别 -->
		<div class="goods-tab">
			<div :class="['item', { active: goodsTab == 0 }]" @click="goodsTab = 0">今日爆款</div>
			<div :class="['item', { active: goodsTab == 1 }]" @click="goodsTab = 1">热销榜单</div>
			<div :class="['item', { active: goodsTab == 2 }]" @click="goodsTab = 2">即将下架</div>
			<div :class="['item', { active: goodsTab == 3 }]" @click="goodsTab = 3">精选好物</div>
		</div>

		<!-- 商品列表 -->
		<goods-list :recommends="recommends" :finished="finished" :isShow="isShow" :goodsTab="goodsTab"
			@getMore="getMore" ref="goodsList"></goods-list>
		<!-- 客服组件 -->
		<customer-service></customer-service>

		<!-- 底部公告 -->
		<div class="notice" v-show="showNotice">
			<p class="iconfont icon-laba"></p>
			<van-swipe class="content" :autoplay="3000" :show-indicators="false" :vertical="true">
				<van-swipe-item v-for="(item, index) in bulletins" :key="index">
					<router-link tag="p" :to="'/applypromoter?types=6&id=' + item.bulletin_id">{{ item.title }}
					</router-link>
				</van-swipe-item>
			</van-swipe>
			<p class="iconfont icon-guanbi" @click="showNotice = false"></p>
		</div>

		<!-- 底部菜单 -->
		<tabbar :selectedId="selectedId"></tabbar>
	</div>
</template>
<script>
	import {
		lunbo,
		column,
		bulletin,
		cardsListsBySales,
		getCardsListsByHotSales,
		getCardsListsByOffSales,
		getGoodsListsByRecomm
	} from "@/http/api";

	//import $ from "jquery";
	import {
		changeArr
	} from "@/common/data";

	import HomeHeader from "./children/Header";
	import GoodsList from "@/components/goods/GoodsList";
	import CustomerService from "./children/CustomerService";
	import Tabbar from "@/components/Tabbar";

	export default {
		components: {
			HomeHeader,
			GoodsList,
			CustomerService,
			Tabbar
		},
		data() {
			return {
				site_code: "",
				selectedId: "1",
				show: false,
				enty: true,
				isbool: true,

				showNotice: true,

				banners: [],
				columns: [],
				bulletins: [],

				isShow: false,
				isLoad: true,
				pages: 1,
				loading: false,
				finished: false,
				recommends: [],

				goodsTab: 0
			};
		},

		mounted() {
			this.site_code = this.$LStorage.getItem('curSiteInfo').site_code
			this.getHome();
		},
		watch: {
			goodsTab(newV, oldV) {
				this.reSet();
				this.getGoodsList();
			}
		},
		methods: {
			// 验证登录
			getHome() {
				this.reSet();
				this.getHomeData();
			},

			//  lunbo图数据
			async lunbo() {
				const res = await lunbo({
					data: {
						site_code: this.site_code
					}
				});
				if (res.code == 200) {
					this.banners = res.data;
				}
			},

			//  栏目数据
			async column() {
				let res = await column({
					data: {
						site_code: this.site_code
					}
				});

				if (res.code == 200) {
					res = res.data;
					res.forEach((item, index) => {
						if (item.column_name == "积分商城") {
							res.splice(index, 1);
						}
					});
					this.columns = changeArr(res, 10);
				}
			},

			//  获取公告数据
			async bulletin() {
				const res = await bulletin({
					data: {
						site_code: this.site_code
					}
				});
				if (res.code == 200) {
					this.bulletins = res.data;
				}
			},

			//  跳转到外部链接
			toRescue(url) {
				this.$dialog.confirm({
					message: '点击确认将跳转至外站',
				}).then(() => {
					window.location.href = url;
				}).catch(() => {
					// 取消执行
				});
			},

			//  今日爆款
			async getGoodsList() {
				if (this.isLoad) {
					let res;
					switch (this.goodsTab) {
						case 0:
							res = await cardsListsBySales({
								data: {
									pages: this.pages,
									site_code: this.site_code
								}
							});
							break;
						case 1:
							res = await getCardsListsByHotSales({
								data: {
									pages: this.pages,
									site_code: this.site_code
								}
							});
							break;
						case 2:
							res = await getCardsListsByOffSales({
								data: {
									pages: this.pages,
									site_code: this.site_code
								}
							});
							break;
						case 3:
							res = await getGoodsListsByRecomm({
								data: {
									pages: this.pages
								}
							});
							break;
						default:
							break;
					}
					// console.log(res.data)
					this.isShow = true;
					this.$refs.goodsList.changeLoading(false);
					if (res.numPage == res.data.length) {
						this.pages++;
						this.finished = false;
					} else {
						this.isLoad = false;
						this.finished = true;
					}
					this.recommends = this.recommends.concat(res.data);
				}
			},

			//  执行加载更多
			getMore() {
				this.getGoodsList();
			},

			//  切换站点
			getSpotData() {
				this.reSet(); //重置商品列表
				this.getHomeData(); //获取首页数据
			},

			// 加载数据
			getHomeData() {
				this.lunbo();
				this.column();
				this.bulletin();
				this.getGoodsList();
			},

			// 重置商品列表参数
			reSet() {
				this.pages = 1;
				this.isLoad = true;
				this.finished = false;
				this.recommends = [];
			}
		},
		beforeRouteEnter(to, from, next) {
			// 在渲染该组件的对应路由被 confirm 前调用
			// 不！能！获取组件实例 `this`
			// 因为当守卫执行前，组件实例还没被创建
			if (from.name == "Details") {
				to.meta.keepAlive = true;
			} else {
				to.meta.keepAlive = false;
			}
			// console.log(to)
			next();
		},
		activated() {
			this.getSpotData();
		}
	};
</script>
<style lang="scss" scoped>
	.test-box {
		position: fixed;
		top: 50vh;
		left: 50vw;
		transform: translateX(-50%) translateY(-50%);

		width: 60vw;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.btn-test {
		width: 20vw;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #000;
		color: #fff;
		border-radius: 5px;
		z-index: 9999999;
		font-size: 0.24rem;
	}

	.home-wrap {
		width: 100%;
		// height: 100%;
		padding-bottom: 1rem;
	}

	.swiper {
		width: 100%;
		background-color: #fff;
		padding: 0 0.3rem;
		box-sizing: border-box;
		height: 2.75rem;
		// margin-top: 1rem;

		img {
			width: 100%;
			height: 2.75rem;
			display: block;
			border-radius: 0.11rem;
		}

		.mint-swipe-indicators {
			bottom: 0.02rem;
		}

		.mint-swipe-indicator.is-active {
			opacity: 1;
		}
	}

	.swiper-wrap {
		width: 100%;
		background-color: #fff;
		padding: 0 0.3rem;
		box-sizing: border-box;
		height: 5.2rem;

		.mint-swipe-indicator.is-active {
			background: #fd6023;
			opacity: 1;
		}
	}

	.list-wrap {
		// width: 6.9rem;
		height: max-content;
		padding: 0.3rem 0 0.3rem;
		box-sizing: border-box;
		display: flex;
		flex-wrap: wrap;
		//justify-content: space-between;

		// margin: 0 0.3rem;

		.asd {
			width: 20%;
			text-align: center;
			margin-bottom: 0.46rem;

			img {
				// width: 0.8rem;
				height: 0.62rem;
			}

			p {
				font-size: 0.23rem;
				color: black;
				margin-top: 0.23rem;
			}
		}
	}

	.logo {
		width: 100%;
		height: 1.6rem;

		img {
			width: 100%;
			height: 1.6rem;
			display: block;
		}
	}

	.goods-tab {
		background-color: #f4f4f4;
		font-size: 0.3rem;
		display: flex;
		justify-content: center;
		padding: 0.3rem 0.34rem 0;

		.item {
			line-height: 0.88rem;
			margin-right: 0.25rem;
			color: #666;
			font-weight: 600;
		}

		.active {
			color: #333;
			border-bottom: #fd6023 solid 0.05rem;
		}
	}

	.notice {
		width: 100%;
		height: 0.88rem;
		position: fixed;
		left: 0;
		bottom: 1.15rem;
		background-color: rgba($color: #000000, $alpha: 0.5);
		padding: 0 0.24rem;
		box-sizing: border-box;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.icon-laba {
			font-size: 0.45rem;
		}

		.content {
			width: calc(100% - 1.2rem);
			font-size: 0.28rem;
			line-height: 0.88rem;
			height: 0.88rem;

			p {
				width: 100%;
				height: 100%;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				word-break: break-all;
			}
		}
	}
</style>
